.sectionMain {
  padding: 110px 0
}

.sectionMainError {
  padding: 0 !important;
}

.sectionMainDark {
  background: var(--bg);
  padding: 90px 0;
  overflow: hidden
}
.sectionMain:not([class*=dark]) + .sectionMain:not([class*=dark]) {
  padding-top: 0
}
@media (max-width:1079px) {
  .sectionMain {
    padding: 60px 0
  }
  .sectionMainDark {
    padding: 40px 0
  }
}
.sectionMainContainer {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: 1550px) {
    max-width: 1300px;
  }
}
.sectionMainHeader {
  margin-bottom: 60px;
  margin-left: 200px;
}
@media (max-width:1079px) {
  .sectionMainHeader {
    text-align: center;
    margin-bottom: 34px;
    margin-left: 0;
  }
}
.sectionMainHeadline {
  margin: 0;
  font-weight: 700;
  font-size: 70px;
  line-height: 1.4;
  color: var(--text-base);
}

.sectionHeaderError {
  font-weight: 700;
  font-size: 34px;
  line-height: 140%;
  color: #232E23;

  @media (min-width: 1080px) {
    font-size: 70px;
  }
}

.buttonArrow {
  background: url("/images/arrowButton.svg") 86% 46% no-repeat, #4FAF4D;
  @media (min-width: 400px) {
    background: url("/images/arrowButton.svg") 83% 46% no-repeat, #4FAF4D;
  }
  @media (min-width: 1080px) {
    background: url("/images/arrowButton.svg") 89% 46% no-repeat, #4FAF4D;
  }
}

.sectionMainHeadline b,
.sectionMainHeadline strong {
  font-weight: 700
}
.sectionMainHeadline br {
  display: none
}
@media (max-width:1079px) {
  .sectionMainHeadline {
    font-size: 34px;
    line-height: 1.2
  }
  .sectionMainHeadline br {
    display: initial
  }
}
.sectionMainText {
  display: flex;
  align-items: center;
  color: var(--blue-grey-color-200);
  font-size: 24px;
  line-height: 1.4;
  font-weight: 400;
  margin-bottom: 12px;
  gap: 12px
}
@media (max-width:1079px) {
  .sectionMainText {
    font-size: 14px;
    justify-content: center
  }
}
.sectionMainFlag {
  width: 35px;
  height: 26px
}
.sectionMainFlag img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px
}
.sectionMainFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px
}
@media (max-width:1079px) {
  .sectionMainFooter {
    margin-top: 40px
  }
}
.sectionMainMore {
  outline: 0;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  border: 0;
  padding: 22px 58px;
  background: url("/images/aboutUs/arrow22.svg") 90% 47% no-repeat,#4faf4d;
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  letter-spacing: .02em;
  text-transform: uppercase;
  text-align: center;
  border-radius: 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.sectionMainMore:hover {
  background-color: #53cc51
}
.sectionMainMore:active {
  background-color: #47a245
}
@media (max-width: 1079px) {
  .sectionMainMore {
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    padding: 16px 0;
    background: url("/images/aboutUs/arrow22.svg") 86% 47% no-repeat,#4faf4d;
  }
}

.sectionCarousel {
  position: relative
}
@media (max-width:1079px) {
  .sectionCarousel {
    padding-bottom: 50px
  }
}
.sectionCarouselList {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 -14px;
  gap: 47px;
}
@media (max-width:1079px) {
  .sectionCarouselList {
    margin: 0 -20px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch
  }

  .sectionCarouselList::-webkit-scrollbar {
    display: none;
  }
}
.sectionCarouselItem {
  flex: 0 0 30%;
  max-width: 30%;
  background: #FFFBF0;
  border-radius: 30px;
  padding: 20px;
}
@media (max-width:1024px) {
  .sectionCarouselItem {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .sectionCarouselItem:first-child {
   // margin-left: 20px;
  }
  .sectionCarouselItem:last-child {
    //margin-right: 20px;
  }
}

.sectionCarouselPagination {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1
}
@media (max-width:1079px) {
  .sectionCarouselPagination {
    display: flex;
    align-items: center;
    justify-content: center
  }
}
.sectionCarouselBullet {
  width: 30px;
  height: 9px;
  border-radius: 5px;
  background-color: #f1efe9;
  margin: 0 4px;
  cursor: pointer
}
.sectionCarouselBullet.active {
  background: #75726b
}

.newsCard {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  outline: 0
}
.newsCardPic {
  //height: 380px;
  overflow: hidden;
  background: #fdfbf5;
  border-radius: 20px;
  -webkit-mask-image: -webkit-radial-gradient(white,#000)
}
@media (max-width:1079px) {
  .newsCardPic {
    height: 276px;
    margin: 0 auto;
  }
}
.newsCardImg {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 20px;
  transition: transform .5s ease
}
.newsCard:hover .newsCardImg {
  transform: scale(1.1)
}
.newsCardInfo {
  padding-top: 20px
}
@media (max-width:1079px) {
  .newsCardInfo {
    padding-top: 16px
  }
}
.newsCardMeta {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 400;
  margin-bottom: 10px;
  color: #999
}
@media (max-width:1079px) {
  .newsCardMeta {
    font-size: 13px;
    line-height: 17px;
    color: #878781
  }
}
.newsCardHeadline {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: .02em;
  color: #181818
}
@media (max-width:1079px) {
  .newsCardHeadline {
    font-size: 20px
  }
}
.newsCardText {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  margin-top: 8px;
  color: #73818a
}
@media (max-width:1079px) {
  .newsCardText {
    font-size: 14px;
    line-height: 1.6
  }
}