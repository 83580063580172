.sectionMain {
  padding: 60px 0;

  @media (min-width: 1080px) {
    padding: 110px 0;
  }
}

.sectionMainDark {
  background: var(--bg);
  padding: 90px 0;
  overflow: hidden;

  @media (min-width: 1080px) {
    padding: 40px 0;
  }
}

.sectionMain:not([class*=dark]) + .sectionMain:not([class*=dark]) {
  padding-top: 0
}

.buttonArrow {
  background: url("/images/arrowButton.svg") 86% 46% no-repeat, #4FAF4D;

  @media (min-width: 400px) {
    background: url("/images/arrowButton.svg") 83% 46% no-repeat, #4FAF4D;
  }

  @media (min-width: 1080px) {
    background: url("/images/arrowButton.svg") 89% 46% no-repeat, #4FAF4D;
  }
}

.paginationError {
  flex-direction: row-reverse;
}

.sectionMainContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px
}

.sectionMainHeader {
  text-align: center;
  margin-bottom: 34px;

  @media (min-width: 1080px) {
    margin-bottom: 60px;
    text-align: left;
    margin-left: 200px;
  }
}

.sectionMainHeadline {
  margin: 0;
  font-weight: 700;
  font-size: 34px;
  line-height: 1.2;
  color: var(--text-base);

  @media (min-width: 1080px) {
    font-size: 70px;
    line-height: 1.4;
  }
}

.sectionMainHeadline br {
  display: initial;

  @media (min-width: 1080px) {
    display: none;
  }
}

.sectionMainHeadline b,
.sectionMainHeadline strong {
  font-weight: 700;
}

.sectionMainText {
  display: flex;
  align-items: center;
  color: var(--blue-grey-color-200);
  font-size: 14px;
  justify-content: center;
  line-height: 1.4;
  font-weight: 400;
  margin-bottom: 12px;
  gap: 12px;

  @media (min-width: 1080px) {
    font-size: 24px;
    justify-content: left;
  }
}

.sectionMainFlag {
  width: 35px;
  height: 26px;
}

.sectionMainFlag img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}

.sectionMainFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  @media (min-width: 1080px) {
    //margin-top: 80px;
  }
}

.sectionMainMore {
  outline: 0;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  border: 0;
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  padding: 16px 0;
  background-color: #4faf4d;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: .02em;
  text-transform: uppercase;
  text-align: center;
  border-radius: 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (min-width: 1080px) {
    padding: 22px 58px;
    font-size: 20px;
    line-height: 26px;
    width: auto;
  }
}

.sectionMainMore:hover {
  background-color: #53cc51
}
.sectionMainMore:active {
  background-color: #47a245
}

.sectionCarousel {
  position: relative;
  display: grid;
  padding-bottom: 10px;

  @media (min-width: 1080px) {
    padding-bottom: 0;
  }
}

.sectionCarouselNext,
.sectionCarouselPrev {
  display: none;

  @media (min-width: 1080px) {
    position: absolute;
    top: 200px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    outline: 0;
    border: 0;
    background: #4faf4d;
    color: #fff;
    width: 92px;
    height: 92px;
    cursor: pointer;
  }
}

.sectionCarouselNext:hover,
.sectionCarouselPrev:hover {
  background-color: #53cc51;
}
.sectionCarouselNext:active,
.sectionCarouselPrev:active {
  background-color: #47a245;
}
.sectionCarouselNext:after,
.sectionCarouselPrev:after {
  content: "";
  display: block;
  background: url(/images/aboutUs/icon-arrow.svg) center/contain no-repeat;
  width: 20px;
  height: 19px;
}
.sectionCarouselNext.hidden,
.sectionCarouselPrev.hidden {
  opacity: 1;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 250ms ease,visibility 250ms ease;
}

.sectionCarouselNext {
  right: -20px;

  @media (min-width: 1280px) {
    right: -45px;
  }
}

.sectionCarouselPrev {
  left: -20px;

  @media (min-width: 1280px) {
    left: -70px;
  }
}

.sectionCarouselPrev:after {
  transform: scale(-1)
}

.sectionCarouselPagination {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1080px) {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
}

.sectionCarouselBullet {
  width: 30px;
  height: 9px;
  border-radius: 5px;
  background-color: #f1efe9;
  margin: 0 4px;
  cursor: pointer
}
.sectionCarouselBulletActive {
  background-color: #75726b;
  width: 30px;
  height: 9px;
  border-radius: 5px;
  margin: 0 4px;
  cursor: pointer
}

.slider {
  position: relative;
}